













































































































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch, Prop, Emit } from 'vue-property-decorator'
import Connector from '../../components/chargepoint/Connector.vue'
import TechnicalData from '../../components/chargepoint/TechnicalData.vue'
import RemoteStart from './RemoteStart'
import Reset from './Reset'
import ChargePointApi from '../../services/ChargePointApi'
import TariffDialog from './TariffDialog.vue'
import MapApi from '@/services/MapApi'
import { mapGetters } from 'vuex'
import MapDTO from './MapDTO.vue'

@Component({
  components: {
    Connector,
    TechnicalData,
    RemoteStart,
    TariffDialog,
    Reset,
    MapDTO
  },
  computed: {
    ...mapGetters({ userDTA: 'user/userInfo', contractDTA: 'chargepoints/contractDTA' })
  }
})
export default class ChargepointCard extends Vue {
  @Prop(Object) readonly cp: any
  @Prop(String) readonly chargepointId: any

  dialog = false
  step = 0
  isMapFree = false
  isMapPaid = false
  isGuestCharging = 0
  tariffid= {
    description: this.t('$vuetify.subscriptions.SUB_PARTNER_UPPER_NOUPLIFT'),
    code: 'SUB-PARTNER-UPPER-NOUPLIFT'
  }

  tmpObj = {
    Location: {
      Id: '',
      Label: '',
      Street: '',
      Description: '',
      HouseNumber: '',
      City: '',
      ZipCode: '',
      Country: '',
      Latitude: 0,
      Longitude: 0
    }
  }

  get isReimburse (): any {
    let isFound = false

    // @ts-ignore
    if (!this._.isEmpty(this.contractDTA.SubContracts)) {
      // @ts-ignore
      const ctrTab = this.contractDTA.SubContracts
      for (let index = 0; index < ctrTab.length && !isFound; index++) {
        const element = ctrTab[index]
        isFound = (element.ContractType.Id === 6) && this._.isEmpty(element.EndDateTime)
      }
    }
    // @ts-ignore
    if (this.contractDTA.evtype === 'AC') {
      if (isFound) {
        this.isGuestCharging = 2
        this.isMapFree = false
        // @ts-ignore
        this.isMapPaid = this.contractDTA.published
      } else {
        this.isGuestCharging = 1
        // @ts-ignore
        this.isMapFree = this.contractDTA.published
        this.isMapPaid = false
      }
    } else {
      this.isGuestCharging = 0
      this.isMapFree = false
      this.isMapPaid = false
    }
    return isFound
  }

  payload = {
    toggle: 0, // Enable or disable guestcharging
    published: 0, // Make charge public (will show on map, ...)
    reimburse: 0, // Reimburse or no reimburse?
    tariffid: '' // Tariff for reimbursement
  }

  relationId = 0
  isChargeCardAccess = false
  isChargeCardReimbursement = false
  isReimbursement = false
  isCreateTariff = false
  newToken = null

  itemsTariff = [
    { description: this.t('$vuetify.subscriptions.SUB_PARTNER_UPPER_NOUPLIFT'), code: 'SUB-PARTNER-UPPER-NOUPLIFT' }
  ]

  isSaving = false

  mounted () {
    if (!this._.isEmpty(this.cp.Location)) {
      this.tmpObj.Location = this._.clone(this.cp.Location)
    }
  }

  get contractData (): any {
    // @ts-ignore
    return this.contractDTA
  }

  get userData (): any {
    // @ts-ignore
    return this.userDTA
  }

  async save () {
    this.isSaving = true
    if (this.isGuestCharging !== 0 && (this.isMapFree || this.isMapPaid)) {
      // TE VERBETEREN
      await this.updateMap()
    }
    await this.setContract(true) // reset
    await this.setContract() // reset
    this.isSaving = false
    this.dialog = false
    this.$emit('refresh')
  }

  async updateMap () {
    const payload = {
      id: this.tmpObj.Location.Id,
      name: this.tmpObj.Location.Label,
      description: this.tmpObj.Location.Description,
      street: this.tmpObj.Location.Street,
      housenr: this.tmpObj.Location.HouseNumber,
      city: this.tmpObj.Location.City,
      postalcode: this.tmpObj.Location.ZipCode,
      country: this.tmpObj.Location.Country,
      lng: this.tmpObj.Location.Longitude,
      lat: this.tmpObj.Location.Latitude
    }
    try {
      const result: any = await new MapApi().createUpdate(payload)
    } catch (error) {
      console.log(error)
    }
  }

  async setContract (isReset = false) {
    try {
      let payload = {}
      if (isReset) {
        payload = {
          toggle: 0,
          published: 0,
          reimburse: 0,
          subscriptiontypeid: ''
        }
      } else {
        payload = {
          toggle: (this.isGuestCharging !== 0) ? 1 : 0,
          published: ((this.isGuestCharging === 2 && this.isMapPaid) || (this.isGuestCharging === 1 && this.isMapFree)) ? 1 : 0,
          reimburse: (this.isGuestCharging === 2) ? 1 : 0,
          subscriptiontypeid: this.tariffid.code
        }
      }
      const result: any = await new ChargePointApi().guestCharging(payload, this.chargepointId)
    } catch (error) {
      console.log(error)
    }
  }

  updateCoordinates (location: any) {
    this.tmpObj.Location.Latitude = location.latLng.lat()
    this.tmpObj.Location.Longitude = location.latLng.lng()
  }

  getAddress (place: any) {
    this.tmpObj.Location.Latitude = place.geometry.location.lat()
    this.tmpObj.Location.Longitude = place.geometry.location.lng()
    this.fillInAddress(place)
  }

  startConfiguration () {
    this.dialog = true
    this.step = 0
  }

  fillInAddress (place: any) {
    // console.log(place.address_components)
    for (const component of place.address_components) {
      const componentType = component.types[0]

      switch (componentType) {
        case 'street_number': {
          this.tmpObj.Location.HouseNumber = `${component.long_name}`
          break
        }

        case 'route': {
          this.tmpObj.Location.Street = component.short_name
          break
        }

        case 'postal_code': {
          this.tmpObj.Location.ZipCode = `${component.long_name}`
          break
        }

        case 'locality':
          this.tmpObj.Location.City = component.long_name
          break

        case 'country':
          this.tmpObj.Location.Country = component.short_name
          break
      }
    }
  }

  cancel () {
    this.dialog = false
    this.step = 0
  }
}
