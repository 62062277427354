import BaseApi from './BaseApi'
import { RelationContactInterface } from './RelationContactApi'
import RelationTreeConfig from '@/typings/ApiTypings/RelationTreeConfig'
import { AxiosRequestConfig } from 'axios'
interface RelationInterface {
  AccountancyId?: number | string | RelationContactInterface;
  CompanyType?: string;
  CusId?: number | string;
  DeliveryContactId?: number | string | RelationContactInterface;
  Description?: string;
  Email?: string;
  Id?: number | string;
  InvoiceContactId?: number | string | RelationContactInterface;
  IsCompany?: number | string;
  MainContactId?: number | string | RelationContactInterface;
  Name?: string;
  ParentId?: number | string;
  PartnerType?: number | string;
  Phone?: number | string;
  PlanonId?: number | string;
  RelationDescriptionList?: string;
  RelationIdList?: string;
  RemarkInternal?: string;
  RemarkPublic?: string;
  SearchString?: string;
  SupId?: number | string;
  Uci?: string;
  VatNr?: string;
  Reference?: string;
  Street?: string;
  Housenumber?: string;
  City?: string;
  Postalcode?: string;
  Country?: string;
}
class PresetApi extends BaseApi {
  constructor (type = 0) {
    super('Relation', type)
  }

  async filtered (query: string | null = null) {
    const q = {}
    const select = {
      DataCategories: ['BASIC', 'ADMIN'],
      Fields: [
        'Description',
        'CusId',
        'Name',
        'Email',
        'PlanonId',
        'SupId',
        'Phone',
        'VatNr',
        'InvoiceContactId',
        'MainContactId'
      ]
    }
    if (query) {
      // TODO: query bouwen
    }
    return await this.filter(q, { pagenumber: 1, pagesize: 25 }, select)
  }

  async details (id: string | number) {
    const select: any = {
      DataCategories: ['BASIC', 'STATE', 'TECH'],
      Links: [
        {
          Key: 'MainContactId',
          Params: { DataCategories: ['BASIC'] }
        },
        {
          Key: 'InvoiceContactId',
          Params: { DataCategories: ['BASIC'] }
        },
        {
          Key: 'DeliveryContactId',
          Params: { DataCategories: ['BASIC'] }
        }
      ]
    }
    const dt = await this.one(id, select) as any
    return dt
  }

  async getList (id: any) {
    const options = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/contract/BCO/presets?relation=${id}`
    }
    return await this.getApi(options)
  }

  async add (payload: any, id: any) {
    const opts: AxiosRequestConfig = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/contract/BCO/presets?relation=${id}`,
      data: payload
    }
    return await this.postApi(opts)
  }

  async update (payload: any) {
    const opts: AxiosRequestConfig = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/contract/BCO/presets/${payload.ID}`,
      data: payload
    }
    return await this.putApi(opts)
  }

  async remove (id: any) {
    const opts: AxiosRequestConfig = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/contract/BCO/presets/${id}`,
    }
    return await this.deleteApi(opts)
  }

  async getPresetById (id: string) {
    const options = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/contract/BCO/presets/${id}`
    }
    return await this.getApi(options)
  }

  async relationTree (config: RelationTreeConfig = {}, isAsc = true, filter = '{ "description": "" }', directonly=1) {
    let parent = -1
    if (config) {
      if (config.parentId) {
        parent = config.parentId
      }
    }
    let options = {}
    if (filter !== '{ "description": "" }') {
      options = {
        url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/relation/BCO/treeview/${parent}/Relation?directonly=${directonly}&filter=${filter}&sort=description desc`
      }
      if (isAsc) {
        options = {
          url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/relation/BCO/treeview/${parent}/Relation?directonly=${directonly}&filter=${filter}&sort=description asc`
        }
      }
    } else {
      options = {
        url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/relation/BCO/treeview/${parent}/Relation?directonly=${directonly}&sort=description desc`
      }
      if (isAsc) {
        options = {
          url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/relation/BCO/treeview/${parent}/Relation?directonly=${directonly}&sort=description asc`
        }
      }
    }
    return await this.getApi(options)
  }

  async relationAllTree (config: RelationTreeConfig = {}, isAsc = true, filter = '{ "description": "" }', directonly=1) {
    let parent = -1
    if (config) {
      if (config.parentId) {
        parent = config.parentId
      }
    }
    let options = {}
    if (filter !== '{ "description": "" }') {
      options = {
        url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/relation/BCO/treeviewer?sort=description asc`
      }
      if (isAsc) {
        options = {
          url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/relation/BCO/treeviewer?sort=description asc`
        }
      }
    } else {
      options = {
        url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/relation/BCO/treeviewer?sort=description asc`
      }
      if (isAsc) {
        options = {
          url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/relation/BCO/treeviewer?sort=description asc`
        }
      }
    }
    return await this.getApi(options)
  }



  async ChildrenOfElement (parentId: number | string) {
    const options = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/relation/BCO/childelements/${parentId}`
    }
    return await this.getApi(options)
  }

  async createUpdate (relation: RelationInterface) {
    const opts: AxiosRequestConfig = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/relation/BCO/createupdate`,
      data: relation
    }
    return await this.postApi(opts)
  }

  async convertRelation (relationcontactid: any) {
    const opts: AxiosRequestConfig = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/user/BCO/contact/intorelation/${relationcontactid}`
    }
    return await this.postApi(opts, true, true)
  }

  async createGroup (relationid: any, payload: any) {
    const opts: AxiosRequestConfig = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/relation/BCO/${relationid}/whitelistgroup/createupdate`,
      data: payload
    }
    return await this.postApi(opts)
  }

  async getWLGroup (chargepointID: any) {
    const opts: AxiosRequestConfig = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/chargeplacement/BCO/${chargepointID}/whitelistgroup`,
    }
    return await this.getApi(opts)
  }

  async duplicateGroup (TGCode: any, inDTA: any) {
    const code = TGCode.replace('#', '%23')
    // console.log(inDTA)
    const payload = {
      Code: '',
      Description: inDTA.groupName
    }
    const opts: AxiosRequestConfig = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/whitelistgroup/BCO/${code}/clone`,
      data: payload
    }
    // console.log('-----------[DEBUG]')
    // console.log(opts)
    return await this.postApi(opts)
  }

  async addprintedNumber (TGCode: any, WLCode: any, payload: any) {
    const codeTG = TGCode.replace('#', '%23')
    const codeWL = WLCode.replace('#', '%23')
    const opts: AxiosRequestConfig = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/whitelistgroup/BCO/${codeTG}/${codeWL}/addprintednumber`,
      data: payload
    }
    return await this.postApi(opts)
  }

  async createWhiteList (TGCode: any, payload: any) {
    const codeTG = TGCode.replace('#', '%23')
    const opts: AxiosRequestConfig = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/whitelistgroup/BCO/${codeTG}/whitelist/createupdate`,
      data: payload
    }
    return await this.postApi(opts)
  }

  async quickSearch (query: string) {
    const options = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/relation/BCO/quicksearch2?query=${query}`
    }
    return await this.getApi(options)
  }

  async guestCharging (payload: any, chargepointid: any) {
    // console.log('CALL GUEST')
    const options = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/chargeplacement/BCO/${chargepointid}/update/guestcharging`,
      data: payload
    }
    return await this.postApi(options)
  }

  async addSubscriptionType (payload: any, id: any) {
    const opts: AxiosRequestConfig = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/relation/BCO/${id}/createsubscriptiontype`,
      data: payload
    }
    return await this.postApi(opts)
  }

  async getTariffs (relationId: any, filter = '{ "description": "" }') {
    let options = {}
    if (filter !== '{ "description": "" }') {
      options = {
        url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/relation/BCO/${relationId}/subscriptiontype/list/filtered??page[size]=100&page[number]=1&caching=1&filter=${filter}`
      }
    } else {
      options = {
        url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/relation/BCO/${relationId}/subscriptiontype/list/filtered?page[size]=100&page[number]=1&caching=1&includeCreg=1`
      }
    }
    return await this.getApi(options)
  }

  async moveTokenFromGroup (TGCode: any, WLCodeFrom: any, WLCodeTo: any, payload: any) {
    const codeTG = TGCode.replace('#', '%23')
    const codeWL = WLCodeFrom.replace('#', '%23')
    const codeT = WLCodeTo.replace('#', '%23')

    const opts: AxiosRequestConfig = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/whitelistgroup/BCO/${codeTG}/movetokens/${codeWL}/to/${codeT}`,
      data: payload
    }
    return await this.postApi(opts)
  }

  async getWhiteListGroup (relationId: any, filter = '{ "description": "" }') {
    let options = {}
    if (filter !== '{ "description": "" }') {
      filter = JSON.stringify(filter)
      options = {
        url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/relation/BCO/${relationId}/whitelistgroup/list/filtered?page[size]=100&page[number]=1&caching=0&filter=${filter}`
      }
    } else {
      options = {
        url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/relation/BCO/${relationId}/whitelistgroup/list/filtered?page[size]=100&page[number]=1&caching=0`
      }
    }
    return await this.getApi(options)
  }

  async removeWLGroup (relationId: any, TGCode: any) {
    const codeTG = TGCode.replace('#', '%23')
    let options = {}
    options = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/relation/BCO/${relationId}/whitelistgroup/${codeTG}`
    }
    return await this.deleteApi(options)
  }

  async removeTokenWLGroup (TGCode: any, WLCode: any, TCode: any) {
    const codeTG = TGCode.replace('#', '%23')
    const codeWL = WLCode.replace('#', '%23')
    const codeT = TCode.replace('#', '%23')
    let options = {}
    options = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/whitelistgroup/BCO/${codeTG}/${codeWL}/${codeT}`
    }
    return await this.deleteApi(options)
  }

  async setGroup (placementId: any, payload: any) {
    const opts: AxiosRequestConfig = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/chargeplacement/BCO/${placementId}/whitelistgroup`,
      data: payload
    }
    return await this.postApi(opts)
  }

  async getGroup (placementId: any) {
    let options = {}
    options = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/chargeplacement/BCO/${placementId}/whitelistgroup`
    }
    return await this.getApi(options)
  }

  async checkIBAN (iban: any) {
    let options = {}
    options = {
      url: `https://openiban.com/validate/${iban}?getBIC=true`
    }
    return await this.getExterneApi(options)
  }

  async checkVAT (iban: any) {
    let options = {}
    options = {
      url: `https://controleerbtwnummer.eu/api/validate/${iban}.json`
    }
    return await this.getExterneApi(options, false)
  }
}

export { PresetApi, RelationInterface }
export default PresetApi
