





















































































/* eslint-disable */
/* global google:true */

import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref, Watch } from 'vue-property-decorator'
import MapApi from '@/services/MapApi'
import { mapGetters } from 'vuex'

@Component({
  computed: {
    ...mapGetters({ userDTA: 'user/userInfo', contractDTA: 'chargepoints/contractDTA' })
  }
})
export default class ChargepointCard extends Vue {
  @Ref() readonly ChargepointForm!: any
  @Prop(Object) readonly chargePoint: any

  isFormValid = true
  isEditMap = false
  isSaving = false

  fieldChargepoint = {
    id: '',
    label: '',
    description: '',
    street: '',
    housenumber: '',
    zipcode: '',
    country: '',
    city: '',
    latitude: '',
    longitude: '',
    chargepointid: ''
  }

  rules = {
    required: (value: any) => (value && Boolean(value)) || 'Required'
  }

  mounted () {
    this.initModel()
  }

  initModel () {
    if (!this._.isEmpty(this.chargePoint.Location)) {
      this.fieldChargepoint.id = this.chargePoint.Location.Id
      this.fieldChargepoint.label = this.chargePoint.Location.label
      this.fieldChargepoint.street = this.chargePoint.Location.Street
      this.fieldChargepoint.housenumber = this.chargePoint.Location.HouseNumber
      this.fieldChargepoint.zipcode = this.chargePoint.Location.ZipCode
      this.fieldChargepoint.country = this.chargePoint.Location.Country
      this.fieldChargepoint.city = this.chargePoint.Location.City
      this.fieldChargepoint.description = this.chargePoint.Description
      this.fieldChargepoint.latitude = this.chargePoint.Location.Latitude
      this.fieldChargepoint.longitude = this.chargePoint.Location.Longitude
      this.fieldChargepoint.chargepointid = this.chargePoint.Id
    }
  }

  async save () {
    if (this.ChargepointForm && this.ChargepointForm.validate()) {
      this.updateMap()
    }
  }

  async updateMap () {
    this.isSaving = true
    const payload = {
      id: this.fieldChargepoint.id,
      name: this.fieldChargepoint.label,
      description: this.fieldChargepoint.description,
      street: this.fieldChargepoint.street,
      housenr: this.fieldChargepoint.housenumber,
      city: this.fieldChargepoint.city,
      postalcode: this.fieldChargepoint.zipcode,
      country: this.fieldChargepoint.country,
      lng: this.fieldChargepoint.longitude,
      lat: this.fieldChargepoint.latitude,
      chargepointid: this.chargePoint.Id
    }
    try {
      const result: any = await new MapApi().createUpdate(payload)
      this.isSaving = false
      this.$emit('refresh')
      this.isEditMap = false
    } catch (error) {
      this.isSaving = false
    }
  }

  cancel () {
    this.resetChargepoint()
    this.ChargepointForm.resetValidation()
    this.isEditMap = false
  }

  resetChargepoint () {
    this.initModel()
  }

  @Watch('chargePoint.Id')
  idOnChanged () {
    this.initModel()
  }
}
