



















































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch, Prop, Emit } from 'vue-property-decorator'
import Connector from '../../components/chargepoint/Connector.vue'
import TechnicalData from '../../components/chargepoint/TechnicalData.vue'
import RemoteStart from './RemoteStart'
import Reset from './Reset'
import ChargePointApi from '../../services/ChargePointApi'
import moment from 'moment'
import TariffDialog from './TariffDialog.vue'
import { mapGetters } from 'vuex'

@Component({
  components: {
    Connector,
    TechnicalData,
    RemoteStart,
    TariffDialog,
    Reset
  },
  filters: {
    formatDateTime (val: string) {
      if (val) {
        const b = moment(val, 'YYYY-MM-DD').format('DD/MM/YYYY')
        return b
      }
      return ''
    }
  },
  computed: {
    ...mapGetters({ userDTA: 'user/userInfo', contractDTA: 'chargepoints/contractDTA' })
  }
})
export default class ChargepointCard extends Vue {
  @Prop(Object) readonly cp: any
  @Prop(Object) readonly usrDTA: any
  @Prop(String) readonly chargepointId: any
  @Prop(Boolean) readonly loadingPlugCharge: any

  dialog = false

  plugCharge = {
    isPlugCharge: false,
    isReimbursement: false,
    tariffid: {
      description: this.t('$vuetify.subscriptions.SUB_PARTNER_UPPER_NOUPLIFT'),
      code: 'SUB-PARTNER-UPPER-NOUPLIFT'
    }
  }

  itemsTariff = [
    { description: this.t('$vuetify.subscriptions.SUB_PARTNER_UPPER_NOUPLIFT'), code: 'SUB-PARTNER-UPPER-NOUPLIFT' }
  ]

  loadTarif = false
  isSaving = false

  get contractData (): any {
    // @ts-ignore
    return this.contractDTA
  }

  get isReimburse (): any {
    let isFound = false
    // @ts-ignore
    if (!this._.isEmpty(this.contractDTA.SubContracts)) {
      // @ts-ignore
      const ctrTab = this.contractDTA.SubContracts
      for (let index = 0; index < ctrTab.length && !isFound; index++) {
        const element = ctrTab[index]
        isFound = (element.ContractType.Id === 5) && this._.isEmpty(element.EndDateTime)
      }
    }
    // @ts-ignore
    this.plugCharge.isPlugCharge = this.contractDTA.isplugandcharge
    this.plugCharge.isReimbursement = isFound
    this.plugCharge.tariffid = { description: this.t('$vuetify.subscriptions.SUB_PARTNER_UPPER_NOUPLIFT'), code: 'SUB-PARTNER-UPPER-NOUPLIFT' }
    return isFound
  }

  async save () {
    this.isSaving = true
    try {
      await this.setContract(true) // reset
      await this.setContract() // update
      this.dialog = false
      this.$emit('refresh')
    } catch (error) {
      console.log(error)
    }
    this.isSaving = false
  }

  async setContract (isReset = false) {
    try {
      let payload = {}
      if (isReset) {
        payload = {
          toggle: 0,
          reimburse: 0,
          subscriptiontypeid: ''
        }
      } else {
        payload = {
          toggle: this.plugCharge.isPlugCharge ? 1 : 0,
          reimburse: this.plugCharge.isReimbursement ? 1 : 0,
          subscriptiontypeid: this.plugCharge.tariffid.code
        }
      }
      const result = await new ChargePointApi().plugCharging(payload, this.chargepointId)
    } catch (error) {
      console.log(error)
    }
  }

  startConfiguration () {
    this.dialog = true
  }

  cancel () {
    this.dialog = false
  }
}
