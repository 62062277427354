





























































import ChargeSessionApi from '../../services/ChargeSessionApi'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import SessionDetails from '../../components/sessions/SessionDetails.vue'
import CdrDuration from '../../components/sessions/CdrDuration.vue'
import Time from '@/functions/Time'
import BcSimpleTable from '@/components/_base/BcSimpleTable'

@Component({
  components: {
    BcSimpleTable,
    CdrDuration,
    SessionDetails
  },
  filters: {
    tokWh(val: string | number) {
      if (typeof val === 'string') {
        val = Number(val)
      }
      val = val / 10
      val = Math.round(val)
      return val / 100
    },
    formatEuro(val: any) {
      const valRt = (val / 1).toFixed(3).replace('.', ',')
      return valRt + ' €'
    },
    emptyDt(val: any) {
      if (val === 'undefined €' || val === ' €') {
        val = '0,000 €'
      }
      return val
    },
    toPass(val: any) {
      if (val === '') {
        return 'Non-Blue Corner Pass' // this.t('$vuetify.myTokens.nonBluecorner')
      }
      return val
    }
  }
})
export default class LastTenSessions extends Vue {
  @Prop(String) readonly id: any
  pastSessions: Array<any> = []
  loading = false
  dialog = false
  selected = 0

  mounted() {
    this.fetchPastSessions()
  }

  calcDiff(start: string, end: string) {
    return Time.durationTime(start, end, 'd[Days] HH[h]mm[m]')
  }

  async fetchPastSessions() {
    this.loading = true
    const dt = await new ChargeSessionApi().getTenLastSessions(this.id)
    this.loading = false
    this.pastSessions = dt
  }
}
