








































import AuthorizeApi from '../../services/AuthorizeApi'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch, Prop, Emit } from 'vue-property-decorator'
import moment from 'moment'

@Component({
  filters: {
    formatDateTime (val: string) {
      if (val) {
        const b = moment(val, 'YYYY-MM-DD').format('DD/MM/YYYY')
        return b
      }
      return ''
    }
  }
})
export default class LastTenSessions extends Vue {
  @Prop(Object) readonly cp: any
  @Prop(String) readonly cpID: any
  pastSessions: Array<any> = []
  loading = false

  mounted () {
    if (this.cp) {
      if (this.cp.Id) {
        this.fetchPastSessions()
      }
    }
  }

  async fetchPastSessions () {
    this.loading = true
    const ids: Array<string|number> = []
    for (let i = 0; i < this.cp.Placements.length; i++) {
      const e = this.cp.Placements[i]
      ids.push(Number(e.Id))
    }
    if (ids.length > 0) {
      const dt = await new AuthorizeApi().TenLastAuthorizesFromPlacents(this.cpID)
      this.pastSessions = dt
    }
    this.loading = false
  }
}
