

































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import Connector from '../../components/chargepoint/Connector.vue'
import { find } from 'lodash'
import TechnicalData from '../../components/chargepoint/TechnicalData.vue'
import RemoteStart from './RemoteStart'
import Reset from './Reset'
import ChargePointApi from '../../services/ChargePointApi'
import moment from 'moment'
import TariffDialog from './TariffDialog.vue'
import RelationApi from '@/services/RelationApi'
import UserApi from '@/services/UserApi'

@Component({
  components: {
    Connector,
    TechnicalData,
    RemoteStart,
    TariffDialog,
    Reset
  },
  filters: {
    formatDateTime (val: string) {
      if (val) {
        const b = moment(val, 'YYYY-MM-DD').format('DD/MM/YYYY')
        return b
      }
      return ''
    }
  }
})
export default class ChargepointCard extends Vue {
  @Prop(Object) readonly cp: any
  @Prop(String) readonly chargepointId: any
  moreInfo = false
  isOpen: number | undefined = 0
  panel = [0, 1, 2]
  date = null
  dateBis = null
  menu = false
  menuBis = false
  modal = false
  modalBis = false
  tariffsTab = []

  payloadContract: any = {
    SubscriptionType: '',
    StartDateTime: '',
    EndDateTime: '',
    TransferFormat: '',
    TransferMail: '',
    TransferPeriod: '',
    TransferType: ''
  }

  headersContracts = [
    { text: this.t('$vuetify.chargepoint.type'), value: 'ContractType' },
    { text: this.t('$vuetify.chargepoint.actifsSince'), value: 'FromRelation' },
    { text: this.t('$vuetify.chargepoint.to'), value: 'prinToRelationtednr' },
    { text: this.t('$vuetify.chargepoint.invoiceType'), value: 'StartDateTime' },
    { text: this.t('$vuetify.chargepoint.subType'), value: 'SubscriptionType' },
    { text: this.t('$vuetify.chargepoint.publicChargingCp'), value: 'InvoiceType' },
    { text: this.t('$vuetify.chargepoint.tariff'), value: 'Tariff' }
  ]

  dialogEdit = false
  relationId = 0
  data = {}

  locDialog = false
  isEditDialog = false

  mounted () {
    this.checkUserInfo()
    // this.getContracts()
  }

  refresh () {
    this.getTariffs()
  }

  async getContracts () {
    try {
      const data = await new ChargePointApi().contracts(this.chargepointId) as any
      this.data = data
    } catch (error) {
      console.log(error)
    }
  }

  async getTariffs () {
    try {
      const data = await new RelationApi().getTariffs(this.relationId) as any
      this.tariffsTab = data.data.records
    } catch (error) {
      console.log(error)
    }
  }

  async checkUserInfo () {
    try {
      const result: any = await new UserApi().myInfo()
      this.relationId = result.data.data.Relation.Id
      this.getTariffs()
    } catch (error) {
      console.error(error)
    }
  }

  get colWidthConnector () {
    if (this.cp.Connectors.length < 3) {
      return 6
    } else {
      return 4
    }
  }

  get loc () {
    if (this.cp) {
      if (this.cp.Locations.length > 0) {
        return this.cp.Locations[0]
      }
    }
  }

  state (connector: any) {
    if (connector.SessionState.length > 0) {
      return connector.SessionState
    }
    return connector.State
  }

  getMyPlacement (connector: any) {
    const rtn = find(this.cp.Placements, function (o) {
      return o.ConnectorId === parseInt(connector.Id)
    })
    return rtn
  }
}
