

























































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch, Prop } from 'vue-property-decorator'
import Connector from '../../components/chargepoint/Connector.vue'
import TechnicalData from '../../components/chargepoint/TechnicalData.vue'
import RemoteStart from './RemoteStart'
import Reset from './Reset'
import ChargePointApi from '../../services/ChargePointApi'
import moment from 'moment'
import TariffDialog from './TariffDialog.vue'
import { mapGetters } from 'vuex'
import RelationApi from '@/services/RelationApi'
import PresetApi from '@/services/PresetApi'
import SubscriptionApi from '@/services/SubscriptionApi'
import IconBadgeSettings from './IconBadgeSettings.vue'

@Component({
  components: {
    Connector,
    TechnicalData,
    RemoteStart,
    TariffDialog,
    Reset,
    IconBadgeSettings
  },
  filters: {
    formatDateTime(val: string) {
      if (val) {
        const b = moment(val, 'YYYY-MM-DD').format('DD/MM/YYYY')
        return b
      }
      return ''
    }
  },
  computed: {
    ...mapGetters({
      userDTA: 'user/userInfo',
      contractDTA: 'chargepoints/contractDTA'
    })
  }
})
export default class ChargepointCard extends Vue {
  @Prop(Object) readonly cp: any
  @Prop(Object) readonly usrDTA: any
  @Prop(Object) readonly contractState: any
  @Prop(String) readonly chargepointId: any
  @Prop(Boolean) readonly loadingPlugCharge: any

  isLoadingSettings = true
  settingsSynced: any = {}
  initSettings: any = {}
  currentSettingShow = false
  presetType = 'custom'
  dialog = false
  show = false
  isLoadingTree = false
  isDisabled = false
  modeState = process.env.VUE_APP_MODE
  reimburserList: Array<any> = []
  plugCharge = {
    isPlugCharge: false,
    isReimbursement: false,
    tariffid: {
      description: this.t('$vuetify.subscriptions.SUB_PARTNER_UPPER_NOUPLIFT'),
      code: 'SUB-PARTNER-UPPER-NOUPLIFT'
    }
  }

  itemsTariff = [
    {
      description: this.t('$vuetify.subscriptions.SUB_PARTNER_UPPER_NOUPLIFT'),
      code: 'SUB-PARTNER-UPPER-NOUPLIFT'
    }
  ]

  loadTarif = false
  isSaving = false

  errorTab: any = []

  modelPreset = {
    ID: '',
    Type: 1, // Type 1: Company charger, Type 2: Home charger, Type 3: Home Charger + P&C
    InstallationType: 0, // 0 RFID, 1 Plug &
    IsDefault: 0,
    Name: '',
    accessCard: {
      tokenList: [],
      groupCode: '',
      rfidTokensReimb: [],
      tokensReimb: [],
      oldTokens: [],
      tokensREIMBReimb: [],
      rfidREIMBTokensReimb: []
    },
    Data: {
      GuestCharging: {
        IsEnabled: false,
        IsPublished: false,
        IsReimbursed: false,
        ReimburserId: 0,
        SubscriptionTypeId: '',
        GuestSub: '0',
        CustomFee: null
      },
      PlugAndCharge: {
        IsEnabled: false,
        IsReimbursed: false,
        SubscriptionTypeId: '', // Het 1 of het ander
        CustomFee: '',
        GuestSub: '0',
        ReimburserId: 0
      },
      WhiteListing: {
        IsEnabled: false,
        IsReimbursed: false,
        GuestSub: '0',
        SubscriptionTypeId: '',
        ReimburserId: 0,
        CustomFee: ''
      }
    }
  }

  mounted() {
    this.getTree()
    this.firstInitSettings()
  }

  get userData(): any {
    // @ts-ignore
    return this.userDTA
  }

  initState(type: string) {
    if (type === 'current') {
      if (this.settingsSynced.data.CurrentPlacements.length > 0) {
        this.initPreset(this.settingsSynced.data.CurrentPlacements[0])
      }
    } else {
      if (this.settingsSynced.data.FuturePlacements.length > 0) {
        this.initPreset(this.settingsSynced.data.FuturePlacements[0])
      }
    }
  }

  async getTree() {
    this.isLoadingTree = true
    const dt = (await new RelationApi(0).relationAllTree(true, '')) as any
    this.reimburserList = [this.formatTree(dt.data, true)]
    this.isLoadingTree = false
  }

  formatTree(object: any, isRoot = false) {
    if (isRoot) {
      const obj = object.parent
      if (obj.children) {
        delete object.parent
        obj.children = []
        const keys = Object.keys(object)
        for (let i = 0; i < keys.length; i++) {
          obj.children = obj.children.concat(object[keys[i]])
        }
      }
      return obj
    } else {
      let array: Array<any> = []
      delete object.parent
      const keys = Object.keys(object)
      for (let i = 0; i < keys.length; i++) {
        array = array.concat(object[keys[i]])
      }
      return array
    }
  }

  get contractData(): any {
    // @ts-ignore
    return this.contractDTA
  }

  get isReimburse(): any {
    let isFound = false
    // @ts-ignore
    if (!this._.isEmpty(this.contractState.SubContracts)) {
      // @ts-ignore
      const ctrTab = this.contractState.SubContracts
      for (let index = 0; index < ctrTab.length && !isFound; index++) {
        const element = ctrTab[index]
        isFound =
          element.ContractType.Id === 5 && this._.isEmpty(element.EndDateTime)
      }
    }
    // @ts-ignore
    this.plugCharge.isPlugCharge = this.contractState.isplugandcharge
    this.plugCharge.isReimbursement = isFound
    this.plugCharge.tariffid = {
      description: this.t('$vuetify.subscriptions.SUB_PARTNER_UPPER_NOUPLIFT'),
      code: 'SUB-PARTNER-UPPER-NOUPLIFT'
    }
    return isFound
  }

  get isReimburseGuest(): any {
    let isFound = false

    // @ts-ignore
    if (!this._.isEmpty(this.contractState.SubContracts)) {
      // @ts-ignore
      const ctrTab = this.contractState.SubContracts
      for (let index = 0; index < ctrTab.length && !isFound; index++) {
        const element = ctrTab[index]
        isFound =
          element.ContractType.Id === 6 && this._.isEmpty(element.EndDateTime)
      }
    }
    return isFound
  }

  async update() {
    this.isSaving = true
    this.errorTab = []
    // check if company
    if (this.modelPreset.Type === 1) {
      // create placement
      const chargePointId = this.chargepointId
      // update plug & charge config
      await this.createPlugCharge(chargePointId)
      // update guest charge config
      await this.creatGuestCharge(chargePointId)
      // update whitelist config
      if (
        this.modelPreset.accessCard.tokensReimb.length > 0 ||
        this.modelPreset.accessCard.rfidTokensReimb.length > 0
      ) {
        // Create new Group:
        let groupCode = '-1'
        if (
          this.modelPreset.accessCard.groupCode !== '' &&
          this.modelPreset.accessCard.groupCode !== undefined
        ) {
          groupCode = this.modelPreset.accessCard.groupCode
        } else {
          const relationID = this.userData.data.Relation.Id
          groupCode = await this.createGroup(chargePointId, relationID)
        }
        if (groupCode !== '-1') {
          // Create white list
          // Will always be costless for company charger
          const whitelistCode = await this.createWhitelist(
            groupCode,
            'COSTLESS',
            false
          )
          if (whitelistCode !== -1) {
            // Add Blue corner cards :
            const rslt = await this.addTokenWhitelist(
              this.modelPreset.accessCard.tokensReimb,
              groupCode,
              whitelistCode
            )
            if (rslt !== -1) {
              // Add Externe cards :
              const rslt = await this.addExternTokenWhitelist(
                this.modelPreset.accessCard.rfidTokensReimb,
                groupCode,
                whitelistCode
              )
              if (rslt !== -1) {
                // Add group to chargepoint
                // TODO
                const rslt = await this.addGroupChargepoint(
                  groupCode,
                  chargePointId
                )
                // this.addReimbWhiteList(chargePointId, this.elmDTA.accessCard.subscriptiontypeid)
              }
            }
          }
        }
      }
      this.$emit('refresh')
    } else {
      const chargePointId = this.chargepointId
      // update plug & charge
      await this.createPlugCharge(chargePointId)
      // update guest charging
      await this.creatGuestCharge(chargePointId)
      if (this.modelPreset.InstallationType !== 2) {
        if (
          this.modelPreset.Data.WhiteListing.IsReimbursed ||
          this.modelPreset.Data.GuestCharging.IsEnabled
        ) {
          // Whitelist :
          // check if group exist :
          let groupCode = '-1'
          if (
            this.modelPreset.accessCard.groupCode !== '' &&
            this.modelPreset.accessCard.groupCode !== undefined
          ) {
            groupCode = this.modelPreset.accessCard.groupCode
          } else {
            const relationID = this.userData.data.Relation.Id
            groupCode = await this.createGroup(chargePointId, relationID)
          }
          if (groupCode !== '-1') {
            // Create white list
            // Check if the're free cards:
            if (
              this.modelPreset.accessCard.tokensReimb.length > 0 ||
              this.modelPreset.accessCard.rfidTokensReimb.length > 0
            ) {
              const whitelistCode = await this.createWhitelist(
                groupCode,
                'COSTLESS',
                false
              )
              if (whitelistCode !== -1) {
                const rslt = await this.addTokenWhitelist(
                  this.modelPreset.accessCard.tokensReimb,
                  groupCode,
                  whitelistCode
                )
                if (rslt !== -1) {
                  // Add Externe cards :
                  const rslt = await this.addExternTokenWhitelist(
                    this.modelPreset.accessCard.rfidTokensReimb,
                    groupCode,
                    whitelistCode
                  )
                }
              }
            }
            if (this.modelPreset.Data.WhiteListing.IsReimbursed) {
              // Get current custom tariff :
              let currentTarrif = '-1'
              if (
                this.modelPreset.Data.WhiteListing.CustomFee !== '' &&
                this.modelPreset.Data.WhiteListing.CustomFee !== null
              ) {
                currentTarrif = this.modelPreset.Data.WhiteListing.CustomFee
              } else {
                currentTarrif =
                  this.modelPreset.Data.WhiteListing.SubscriptionTypeId
              }
              // Check if a group already exist current custom tarriff
              let searchWLCODE = '-1'
              const previousOldTokens = []
              if (currentTarrif !== '-1') {
                for (
                  let index = 0;
                  index < this.modelPreset.accessCard.oldTokens.length;
                  index++
                ) {
                  const element: any =
                    this.modelPreset.accessCard.oldTokens[index]
                  if (element.Descripton === currentTarrif) {
                    searchWLCODE = element.Code
                  } else {
                    if (element.Descripton !== 'COSTLESS') {
                      for (
                        let index = 0;
                        index < element.PrintedNumbers.length;
                        index++
                      ) {
                        const token = element.PrintedNumbers[index]
                        const payload = {
                          PrintedNumber: token,
                          Description: token
                        }
                        previousOldTokens.push(payload)
                        // const data = await new RelationApi().removeTokenWLGroup(groupCode, element.Code, element.Token[index]) as any
                      }
                      // remove empty whitelist
                      const pload = {
                        TariffCode: element.TariffCode
                      }
                      const data = (await new RelationApi().deleteWhitelist(
                        groupCode,
                        element.Code,
                        pload
                      )) as any
                    }
                  }
                }
              }
              // If group already exist, user only add new tokens to the same tariff:
              if (searchWLCODE !== '-1') {
                // Add Blue corner cards :
                const rslt = await this.addTokenWhitelist(
                  this.modelPreset.accessCard.tokensREIMBReimb,
                  groupCode,
                  searchWLCODE
                )
                if (rslt !== -1) {
                  // Add Externe cards :
                  const rslt = await this.addExternTokenWhitelist(
                    this.modelPreset.accessCard.rfidREIMBTokensReimb,
                    groupCode,
                    searchWLCODE
                  )
                }
              } else {
                // selected tariff not found in the whitelistlist.
                // Create new whitelist tariff for the new price
                let whitelistCode: any = -1
                whitelistCode = await this.createWhitelist(
                  groupCode,
                  currentTarrif,
                  false
                )
                // Add Blue corner cards :
                const rslt = await this.addTokenWhitelist(
                  this.modelPreset.accessCard.tokensREIMBReimb,
                  groupCode,
                  whitelistCode
                )
                if (rslt !== -1) {
                  // Add Externe cards :
                  const rslt = await this.addExternTokenWhitelist(
                    this.modelPreset.accessCard.rfidREIMBTokensReimb,
                    groupCode,
                    whitelistCode
                  )
                }

                // move all old tokens to the new tariff :
                // Add Blue corner cards :
                const rsltOld = await this.addTokenWhitelist(
                  previousOldTokens,
                  groupCode,
                  whitelistCode
                )
                /* if (rsltOld !== -1) {
                  // Add Externe cards :
                  const rslt = await this.addExternTokenWhitelist(this.modelPreset.accessCard.rfidREIMBTokensReimb, groupCode, whitelistCode)
                } */
              }
            }
            // Add group to chargepoint
            const rslt = await this.addGroupChargepoint(
              groupCode,
              chargePointId
            )
            // Active reimbursement if activated
            // [TODO]
            /* if (rslt !== -1 && this.modelPreset.Data.WhiteListing.IsReimbursed) {
              // this.elmDTA.accessCard.subscriptiontypeid
              if (this.modelPreset.Data.WhiteListing.CustomFee !== '' && this.modelPreset.Data.WhiteListing.CustomFee !== null) {
                console.log('BY PASS FIRST IF')
                this.addReimbWhiteList(chargePointId, this.modelPreset.Data.WhiteListing.CustomFee)
              } else {
                console.log('IN ELSE')
                this.addReimbWhiteList(chargePointId, this.modelPreset.Data.WhiteListing.SubscriptionTypeId)
              }
            } */
          }
        }
      }
      // update reimbursement whitelist
      if (this.modelPreset.Data.WhiteListing.IsReimbursed) {
        if (
          this.modelPreset.Data.WhiteListing.CustomFee !== '' &&
          this.modelPreset.Data.WhiteListing.CustomFee !== null
        ) {
          this.addReimbWhiteList(
            chargePointId,
            this.modelPreset.Data.WhiteListing.CustomFee
          )
        } else {
          this.addReimbWhiteList(
            chargePointId,
            this.modelPreset.Data.WhiteListing.SubscriptionTypeId
          )
        }
      } else {
        this.addReimbWhiteList(chargePointId, -1)
      }
    }
    this.$emit('refresh')
    this.isSaving = false
    this.show = false
  }

  async addReimbWhiteList(chargepointID: any, subscriptiontypeid: any) {
    try {
      if (subscriptiontypeid !== -1) {
        subscriptiontypeid = subscriptiontypeid.replace('_PRIVATE', '')
        subscriptiontypeid = subscriptiontypeid.replace('_AC', '')
      }
      const dta = {
        toggle: 1,
        reimbsubtypeid: subscriptiontypeid,
        reimburserid: this.modelPreset.Data.WhiteListing.ReimburserId,
        presetid: ''
      }
      if (subscriptiontypeid === -1) {
        dta.toggle = 0
      }
      await new ChargePointApi().reimbWL(dta, chargepointID)
      return 1
    } catch (error) {
      console.log(error)
      this.errorTab.push({
        code: 'INVSETREIMBWHITELIST',
        content: 'There was an error setting reimburse to whitelist.'
      })
      return -1
    }
  }

  async addGroupChargepoint(groupCode: any, chargepointID: any) {
    try {
      const payloadSetGroup = {
        WhiteListGroupCode: groupCode
      }
      await new RelationApi().setGroup(chargepointID, payloadSetGroup)
      return 1
    } catch (error) {
      this.errorTab.push({
        code: 'INVSETGRP',
        content: 'There was an error setting group to chargepoint.'
      })
      return -1
    }
  }

  async addExternTokenWhitelist(
    tokens: any,
    groupCode: any,
    whitelistCode: any
  ) {
    try {
      for (let index = 0; index < tokens.length; index++) {
        const element = tokens[index]
        const payload = {
          Id: element.Id,
          Description: element.Description
        }
        await new RelationApi().addPrintedAuthorizeNumber(
          groupCode,
          whitelistCode,
          payload
        )
      }
      return 1
    } catch (error) {
      console.log(error)
      this.errorTab.push({
        code: 'INVADDTOKENWHITELIST',
        content: 'There was an error adding tokens to whitelist.'
      })
      return -1
    }
  }

  async addTokenWhitelist(tokens: any, groupCode: any, whitelistCode: any) {
    try {
      for (let index = 0; index < tokens.length; index++) {
        const element = tokens[index]
        const payload = {
          PrintedNumber: element.PrintedNumber,
          Description: element.Description
        }
        await new RelationApi().addprintedNumber(
          groupCode,
          whitelistCode,
          payload
        )
      }
      return 1
    } catch (error) {
      this.errorTab.push({
        code: 'INVADDTOKENWHITELIST',
        content: 'There was an error adding tokens to whitelist.'
      })
      return -1
    }
  }

  async createWhitelist(
    groupCode: any,
    subscriptiontypeid: any,
    isReimburse: any
  ) {
    try {
      // Add Create Whitelist costless :
      const payload = {
        Description: subscriptiontypeid,
        TariffCode: subscriptiontypeid,
        InvoiceType: '',
        InvoiceRelationId: ''
      }
      // check if whitelist contains the received subscriptiontypeid:
      const tokenArray = (await new PresetApi().getWLGroup(
        this.chargepointId
      )) as any
      let containsSUB = false
      let code = ''
      if (!this._.isEmpty(tokenArray.data.WhiteListGroup)) {
        for (
          let index = 0;
          index < tokenArray.data.WhiteListGroup.WhiteListList.length &&
          !containsSUB;
          index++
        ) {
          const element = tokenArray.data.WhiteListGroup.WhiteListList[index]
          if (element.TariffCode === subscriptiontypeid) {
            containsSUB = true
            code = element.Code
          }
        }
      }
      if (!containsSUB) {
        const bankRslt: any = await new RelationApi().createWhiteList(
          groupCode,
          payload
        )
        return bankRslt.data.Code
      } else {
        return code
      }
    } catch (error) {
      console.log(error)
      this.errorTab.push({
        code: 'INVWHITELISTGRP',
        content: 'There was an error creating white list.'
      })
      return -1
    }
  }

  async createGroup(chargepointID: any, relationID: any) {
    try {
      const payload = {
        Code: 'R' + relationID + 'CP' + chargepointID,
        Description: 'G' + 'R' + relationID + 'CP' + chargepointID,
        WhiteListList: []
      }
      const data = (await new RelationApi().createGroup(
        relationID,
        payload
      )) as any
      return data.data.Code
    } catch (error) {
      this.errorTab.push({
        code: 'INVGRPTOKEN',
        content: 'There was an error creating tokens group.'
      })
      return -1
    }
  }

  async creatGuestCharge(id: any) {
    try {
      const payload: any = {
        toggle: this.modelPreset.Data.GuestCharging.IsEnabled ? 1 : 0,
        published: this.modelPreset.Data.GuestCharging.IsPublished ? 1 : 0,
        reimburse: this.modelPreset.Data.GuestCharging.CustomFee !== '' ? 1 : 0, // see if need to be changed ?
        // reimburse: this.modelPreset.Data.GuestCharging.IsReimbursed ? 1 : 0,
        isfree:
          this.modelPreset.Data.GuestCharging.SubscriptionTypeId === 'COSTLESS'
            ? 1
            : 0,
        isnetworkpartner:
          this.modelPreset.Data.GuestCharging.SubscriptionTypeId === '2'
            ? 1
            : 0,
        reimburser: '', // will be changed
        customfee: this.modelPreset.Data.GuestCharging.CustomFee, // terug betaling
        subscriptiontypeid: 'SUB-PARTNER-UPPER-NOUPLIFT' //  this.modelPreset.Data.guestcharge.tariffid.Code
      }

      if (
        this.modelPreset.Data.GuestCharging.SubscriptionTypeId ===
        'SUB_PARTNER_LOCATION25'
      ) {
        payload.isnetworkpartner = 1
      }

      if (payload.isfree === 1 || payload.isnetworkpartner === 1) {
        payload.customfee = ''
        payload.reimburse = 0
      }
      if (payload.isfree === 1) {
        payload.reimburse = 0
      }
      if (payload.toggle === 0) {
        payload.reimburse = 0
        payload.customfee = ''
        payload.subscriptiontypeid = ''
      }

      await new ChargePointApi().guestCharging(payload, id)
      return 1
    } catch (error) {
      this.errorTab.push({
        code: 'INVGUESTCHARGE',
        content: 'There was an error activating guest charging.'
      })
      return -1
    }
  }

  async createPlugCharge(id: any) {
    try {
      const payload = {
        toggle: this.modelPreset.InstallationType === 2 ? 1 : 0,
        reimburse: this.modelPreset.Data.PlugAndCharge.IsReimbursed ? 1 : 0,
        reimburser: this.modelPreset.Data.PlugAndCharge.ReimburserId,
        customfee: this.modelPreset.Data.PlugAndCharge.CustomFee,
        subscriptiontypeid:
          this.modelPreset.Data.PlugAndCharge.SubscriptionTypeId
      }
      if (!payload.reimburse) {
        payload.customfee = ''
        payload.subscriptiontypeid = ''
      }
      payload.customfee = this.modelPreset.Data.PlugAndCharge.SubscriptionTypeId
      payload.customfee = payload.customfee.replace('_PRIVATE', '')
      payload.subscriptiontypeid = 'SUB-PARTNER-UPPER-NOUPLIFT'
      if (payload.toggle === 0) {
        payload.reimburse = 0
        payload.customfee = ''
        payload.subscriptiontypeid = ''
      }
      if (payload.reimburse === 0) {
        payload.reimburse = 0
        payload.customfee = ''
        payload.subscriptiontypeid = ''
        payload.reimburser = 0
      }
      await new ChargePointApi().plugCharging(payload, id)
      return 1
    } catch (error) {
      this.errorTab.push({
        code: 'INVPLUGCHARGE',
        content: 'There was an error activating plug & charge.'
      })
      return -1
    }
  }

  cancelPreset() {
    this.show = false
  }

  async initPreset(settingsSynced: any) {
    const elm = settingsSynced
    try {
      // get tokens chargepoints
      let tokenArray = (await new PresetApi().getWLGroup(
        this.chargepointId
      )) as any
      const tokenGrpCode = tokenArray.data.WhiteListGroup.Code
      tokenArray = tokenArray.data.WhiteListGroup.WhiteListList
      let tokensTab = (await new SubscriptionApi().list()) as any
      tokensTab = tokensTab.data
      this.modelPreset = {
        ID: this.settingsSynced.data.ID,
        Type: this.settingsSynced.data.Type,
        IsDefault: 0, // niet nodig op de settings pagina
        InstallationType: elm.PlugAndCharge.IsEnabled === 1 ? 2 : 1, // 1 RFID, 2 Plug & Charge
        Name: elm.Name,
        accessCard: {
          tokenList: tokensTab,
          groupCode: tokenGrpCode,
          rfidTokensReimb: [],
          tokensReimb: [],
          oldTokens: tokenArray,
          tokensREIMBReimb: [],
          rfidREIMBTokensReimb: []
        },
        Data: {
          GuestCharging: {
            IsEnabled: elm.GuestCharging.IsEnabled === 1,
            IsPublished: elm.GuestCharging.IsPublished === 1,
            IsReimbursed: elm.GuestCharging.IsReimbursed === 1,
            ReimburserId: 0,
            SubscriptionTypeId: elm.GuestCharging.SubscriptionTypeId,
            GuestSub: '0',
            CustomFee: elm.GuestCharging.CustomFee
          },
          PlugAndCharge: {
            IsEnabled: elm.PlugAndCharge.IsEnabled === 1,
            IsReimbursed: elm.PlugAndCharge.IsReimbursed === 1,
            SubscriptionTypeId: elm.PlugAndCharge.SubscriptionTypeId,
            CustomFee: elm.PlugAndCharge.CustomFee,
            GuestSub: '0',
            ReimburserId: elm.PlugAndCharge.ReimburserId
          },
          WhiteListing: {
            IsEnabled: elm.WhiteListing.IsEnabled === 1,
            IsReimbursed: elm.WhiteListing.IsReimbursed === 1,
            GuestSub: '0',
            SubscriptionTypeId: elm.WhiteListing.SubscriptionTypeId,
            ReimburserId: elm.WhiteListing.ReimburserId,
            CustomFee: elm.WhiteListing.CustomFee
          }
        }
      }
      if (elm.GuestCharging.SubscriptionTypeId === 'COSTLESS') {
        this.modelPreset.Data.GuestCharging.GuestSub = '1'
      } else if (elm.GuestCharging.SubscriptionTypeId === 'SUB_PARTNER_CREG') {
        this.modelPreset.Data.GuestCharging.GuestSub = '3'
      } else {
        if (elm.GuestCharging.SubscriptionTypeId === 'SUB_PARTNER_LOCATION25') {
          // this.modelPreset.Data.GuestCharging.SubscriptionTypeId = ''
        } else {
          this.modelPreset.Data.GuestCharging.GuestSub = '2'
          this.modelPreset.Data.GuestCharging.SubscriptionTypeId = '1'
        }
      }
      if (elm.PlugAndCharge.SubscriptionTypeId === 'SUB_PARTNER_CREG') {
        this.modelPreset.Data.PlugAndCharge.GuestSub = '1'
      } else {
        this.modelPreset.Data.PlugAndCharge.GuestSub = '2'
      }
      if (elm.WhiteListing.SubscriptionTypeId === 'SUB_PARTNER_CREG') {
        this.modelPreset.Data.WhiteListing.GuestSub = '1'
      } else {
        this.modelPreset.Data.WhiteListing.GuestSub = '2'
      }
      if (elm.GuestCharging.CustomFee === 'SUB_PARTNER_CREG') {
        this.modelPreset.Data.GuestCharging.SubscriptionTypeId = '1'
      }

      if (elm.WhiteListing.CustomFee === 'SUB_PARTNER_CREG') {
        this.modelPreset.Data.WhiteListing.GuestSub = '1'
      } else {
      }

      if (
        elm.PlugAndCharge.IsEnabled === 1 &&
        elm.PlugAndCharge.IsReimbursed === 1 &&
        elm.PlugAndCharge.SubscriptionTypeId === '' &&
        elm.PlugAndCharge.CustomFee === ''
      ) {
        this.modelPreset.Data.PlugAndCharge.IsEnabled = false
        this.modelPreset.Data.PlugAndCharge.IsReimbursed = false
      }
      this.show = true
    } catch (error) {
      console.log(error)
    }
  }

  startConfiguration() {
    this.dialog = true
  }

  cancel() {
    this.dialog = false
  }

  @Watch('chargepointId')
  chargeSettings() {
    return this.firstInitSettings()
  }

  async getSettings() {
    try {
      this.isLoadingSettings = true
      this.settingsSynced = (await new ChargePointApi().getSettings(
        this.chargepointId
      )) as any
      this.initSettings = this.settingsSynced
      // this.initPreset(this.settingsSynced)
      this.isLoadingSettings = false
    } catch (error) {
      console.error(error)
    }
  }

  async firstInitSettings() {
    try {
      this.isLoadingSettings = true
      this.settingsSynced = (await new ChargePointApi().getSettings(
        this.chargepointId
      )) as any
      if (this.settingsSynced.data.CurrentPlacements.length > 0) {
        this.initSettings = this.settingsSynced.data.CurrentPlacements[0]
        this.firstInitPreset(this.settingsSynced.data.CurrentPlacements[0])
      }
      this.isLoadingSettings = false
    } catch (error) {
      console.error(error)
    }
  }

  async firstInitPreset(settingsSynced: any) {
    const elm = settingsSynced
    try {
      // get tokens chargepoints
      let tokenArray = (await new PresetApi().getWLGroup(
        this.chargepointId
      )) as any
      const tokenGrpCode = tokenArray.data.WhiteListGroup.Code
      tokenArray = tokenArray.data.WhiteListGroup.WhiteListList
      let tokensTab = (await new SubscriptionApi().list()) as any
      tokensTab = tokensTab.data
      this.modelPreset = {
        ID: this.settingsSynced.data.ID,
        Type: this.settingsSynced.data.Type,
        IsDefault: 0, // elm.IsDefault,
        InstallationType: elm.PlugAndCharge.IsEnabled === 1 ? 2 : 1, // 1 RFID, 2 Plug & Charge
        Name: elm.Name,
        accessCard: {
          tokenList: tokensTab,
          groupCode: tokenGrpCode,
          rfidTokensReimb: [],
          tokensReimb: [],
          oldTokens: tokenArray,
          tokensREIMBReimb: [],
          rfidREIMBTokensReimb: []
        },
        Data: {
          GuestCharging: {
            IsEnabled: elm.GuestCharging.IsEnabled === 1,
            IsPublished: elm.GuestCharging.IsPublished === 1,
            IsReimbursed: elm.GuestCharging.IsReimbursed === 1,
            ReimburserId: 0,
            SubscriptionTypeId: elm.GuestCharging.SubscriptionTypeId,
            GuestSub: '0',
            CustomFee: elm.GuestCharging.CustomFee
          },
          PlugAndCharge: {
            IsEnabled: elm.PlugAndCharge.IsEnabled === 1,
            IsReimbursed: elm.PlugAndCharge.IsReimbursed === 1,
            SubscriptionTypeId: elm.PlugAndCharge.SubscriptionTypeId,
            CustomFee: elm.PlugAndCharge.CustomFee,
            GuestSub: '0',
            ReimburserId: elm.PlugAndCharge.ReimburserId
          },
          WhiteListing: {
            IsEnabled: elm.WhiteListing.IsEnabled === 1,
            IsReimbursed: elm.WhiteListing.IsReimbursed === 1,
            GuestSub: '0',
            SubscriptionTypeId: elm.WhiteListing.SubscriptionTypeId,
            ReimburserId: elm.WhiteListing.ReimburserId,
            CustomFee: elm.WhiteListing.CustomFee
          }
        }
      }
      if (elm.GuestCharging.SubscriptionTypeId === 'COSTLESS') {
        this.modelPreset.Data.GuestCharging.GuestSub = '1'
      } else if (elm.GuestCharging.SubscriptionTypeId === 'SUB_PARTNER_CREG') {
        this.modelPreset.Data.GuestCharging.GuestSub = '3'
      } else {
        if (elm.GuestCharging.SubscriptionTypeId === 'SUB_PARTNER_LOCATION25') {
          // this.modelPreset.Data.GuestCharging.SubscriptionTypeId = ''
        } else {
          this.modelPreset.Data.GuestCharging.GuestSub = '2'
          this.modelPreset.Data.GuestCharging.SubscriptionTypeId = '1'
        }
      }
      if (elm.PlugAndCharge.SubscriptionTypeId === 'SUB_PARTNER_CREG') {
        this.modelPreset.Data.PlugAndCharge.GuestSub = '1'
      } else {
        this.modelPreset.Data.PlugAndCharge.GuestSub = '2'
      }
      if (elm.WhiteListing.SubscriptionTypeId === 'SUB_PARTNER_CREG') {
        this.modelPreset.Data.WhiteListing.GuestSub = '1'
      } else {
        this.modelPreset.Data.WhiteListing.GuestSub = '2'
      }
      if (elm.GuestCharging.CustomFee === 'SUB_PARTNER_CREG') {
        this.modelPreset.Data.GuestCharging.SubscriptionTypeId = '1'
      }

      if (elm.WhiteListing.CustomFee === 'SUB_PARTNER_CREG') {
        this.modelPreset.Data.WhiteListing.GuestSub = '1'
      } else {
      }

      if (
        elm.PlugAndCharge.IsEnabled === 1 &&
        elm.PlugAndCharge.IsReimbursed === 1 &&
        elm.PlugAndCharge.SubscriptionTypeId === '' &&
        elm.PlugAndCharge.CustomFee === ''
      ) {
        this.modelPreset.Data.PlugAndCharge.IsEnabled = false
        this.modelPreset.Data.PlugAndCharge.IsReimbursed = false
      }
    } catch (error) {
      console.log(error)
    }
  }
}
