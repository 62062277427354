




























































































































































































































































































































































































































































import Vue from 'vue'
import { mapGetters } from 'vuex'
import Component from 'vue-class-component'
import { Watch, Prop, Emit } from 'vue-property-decorator'
import Connector from '../../components/chargepoint/Connector.vue'
import TechnicalData from '../../components/chargepoint/TechnicalData.vue'
import RemoteStart from './RemoteStart'
import Reset from './Reset'
import ChargePointApi from '../../services/ChargePointApi'
import moment from 'moment'
import TariffDialog from './TariffDialog.vue'
import RelationApi from '@/services/RelationApi'
import UserApi from '@/services/UserApi'

@Component({
  components: {
    Connector,
    TechnicalData,
    RemoteStart,
    TariffDialog,
    Reset
  },
  filters: {
    formatDateTime (val: string) {
      if (val) {
        const b = moment(val, 'YYYY-MM-DD').format('DD/MM/YYYY')
        return b
      }
      return ''
    }
  },
  computed: {
    ...mapGetters({ userDTA: 'user/userInfo', contractDTA: 'chargepoints/contractDTA' })
  }
})
export default class ChargepointCard extends Vue {
  @Prop(String) readonly chargepointId: any
  @Prop(Object) readonly usrDTA: any
  @Prop(Boolean) readonly isWizard: any

  groupWLActif = {
    Description: '',
    Code: '',
    WhiteListList: []
  }

  debug = false
  isSaving = false
  isLoadingGroups = false
  dialog = false
  step = 3
  absolute = true
  overlay = false
  isShowDelete = false
  isShowDeleteGroup = false
  overlayGroup = false
  headersTokensGroup = []
  payloadDl = {
    item: { Code: '' },
    groupK: '',
    token: { name: '' }
  }

  ex11= ['red', 'indigo', 'orange', 'primary', 'secondary', 'success', 'info', 'warning', 'error', 'red darken-3', 'indigo darken-3', 'orange darken-3']

  payloadDlGrp = {
    name: '',
    code: ''
  }

  curGroup = {
    Code: '',
    Description: '',
    TokenWhiteListList: []
  }

  isReimbursementPlug = false
  isAddCP = false
  itemBanks: any = []
  tariffsTab = []
  isDeleteGrp = false
  isNewChargeCard = false
  isLoadingWLG = false
  tokensGroup = []
  tokenList: any = []
  isLoadingTL = false
  relationId = 0
  isChargeCardAccess = false
  isDuplicate = false
  isEditNameGroup = false
  isChargeCardReimbursement = true
  isReimbursement = false
  isCreateTariff = false
  newToken = null
  isDeleteToken = false
  isLoadingTariff = false
  payload = {
    id: '',
    code: '',
    groupName: '',
    tokenWhiteListList: []
  }

  mounted () {
    this.checkUserInfo()
    this.getGroups()
  }

  cleanup () {
    this.isDuplicate = false
    this.payload = {
      id: '',
      code: '',
      groupName: '',
      tokenWhiteListList: []
    }
  }

  async saveAccess () {
    try {
      this.isSaving = true
      if (this.isChargeCardAccess) {
        const payload = {
          WhiteListGroupCode: this.curGroup.Code
        }
        const data = await new RelationApi().setGroup(this.chargepointId, payload) as any
        const tokenWhiteListList: any = this.curGroup.TokenWhiteListList
        // tokens naar specfiek group tarieven
        for (let index = 0; index < this.tokenList.length; index++) {
          for (let j = 0; j < this.tokenList[index].Tokens.length; j++) {
            const element = this.tokenList[index].Tokens[j]
            // zoek als er een verschillende tarief is toegevoegd
            if (element.tariffCode !== undefined) {
              const payloadBody = {
                TariffCode: element.tariffCode.Code
              }
              const payload = {
                Tokens: [element.name]
              }
              let isFound = false
              let pos = 0
              let found = { Code: '' }
              for (let index = 0; index < tokenWhiteListList.length && !isFound; index++) {
                isFound = tokenWhiteListList[index].TariffCode === element.tariffCode.Code
                found = tokenWhiteListList[index]
                pos = index
              }
              if (!isFound) {
                const bankRslt: any = await new RelationApi().createWhiteList(this.curGroup.Code, payloadBody)
                const from = tokenWhiteListList[index].Code
                const to = bankRslt.data.Code
                const rsltdta: any = await new RelationApi().moveTokenFromGroup(this.curGroup.Code, from, to, payload)
                tokenWhiteListList.push(bankRslt.data)
              } else {
                const from = this.tokenList[index].Code
                const to = found.Code
                const rsltdta: any = await new RelationApi().moveTokenFromGroup(this.curGroup.Code, from, found.Code, payload)
              }
            }
          }
        }
      }
      const dta = {
        toggle: this.isChargeCardAccess ? 1 : 0,
        subscriptiontypeid: 0
      }
      const rsltReimb = await new ChargePointApi().reimbWL(dta, this.chargepointId)
      this.whiteListGroup()
      this.isSaving = false
      this.dialog = false
      this.getGroups()
      this.$emit('refresh')
    } catch (error) {
      console.log(error)
    }
  }

  async getGroups () {
    try {
      // getGroup
      this.isLoadingGroups = true
      const data = await new RelationApi().getGroup(this.chargepointId) as any
      this.groupWLActif = data.data.WhiteListGroup
      this.isLoadingGroups = false
      // @ts-ignore
      this.isChargeCardAccess = this.contractDTA.isreimbtowhitelist
    } catch (error) {
      console.log(error)
    }
  }

  async saveGroup () {
    try {
      const tokenWhiteListList: any = []
      // TODO FIX DUPLICATE
      for (let index = 0; index < this.tokenList.length; index++) {
        let tokens: any = this.tokenList[index].Tokens
        const tkTab = []
        for (let index = 0; index < tokens.length; index++) {
          const tk = tokens[index].name
          tkTab.push(tk)
        }
        tokens = tkTab
        const listTK: any = this.tokenList[index]
        listTK.Tokens = tkTab
        tokenWhiteListList.push(listTK)
      }
      const payload = {
        Code: this.curGroup.Code,
        Description: this.curGroup.Description,
        WLType: 'WhiteList',
        WhiteListList: tokenWhiteListList
      }
      const data = await new RelationApi().createGroup(this.relationId, payload) as any
      this.whiteListGroup()
      this.isDuplicate = false
      // this.step = 0
      this.step = 3
    } catch (error) {
      console.log(error)
    }
  }

  async updateToken (token: any, item: any) {
    const wlCodeFrom = item.Code
    const payload = {
      Tokens: [token.name]
    }
    const wlCodeTo = token.tariffCode.Code
    /* console.log('Code from : ' + wlCodeFrom)
    console.log('Payload')
    console.log(payload)
    console.log('Tariff to : ')
    console.log(token.tariffCode.Code) */
    const tokenWhiteListList: any = this.curGroup.TokenWhiteListList
    let isFound = false
    let pos = 0
    for (let index = 0; index < tokenWhiteListList.length && !isFound; index++) {
      isFound = tokenWhiteListList[index].TariffCode === wlCodeTo
      pos = index
    }
    const wlTariffCodeTo = tokenWhiteListList[pos].Code
    if (isFound) {
      const moveRslt: any = await new RelationApi().moveTokenFromGroup(this.curGroup.Code, wlCodeFrom, wlTariffCodeTo, payload)
      // const addPrntNumbRslt: any = await new RelationApi().addprintedNumber(this.curGroup.Code, wlCodeTo, payload)
      // console.log()
    } else {
      const payloadBody = {
        TariffCode: wlCodeTo
      }
      const bankRslt: any = await new RelationApi().createWhiteList(this.curGroup.Code, payloadBody)
      const moveRslt: any = await new RelationApi().moveTokenFromGroup(this.curGroup.Code, wlCodeFrom, bankRslt.data.Code, payload)
    }
    /* try {
      if (isFound) {
        const wLCode = tokenWhiteListList[pos].Code
        const addPrntNumbRslt: any = await new RelationApi().addprintedNumber(this.curGroup.Code, wLCode, payload)
      } else {
        const payloadBody = {
          TariffCode: 'COSTLESS'
        }
        const bankRslt: any = await new RelationApi().createWhiteList(this.curGroup.Code, payloadBody)
        const addPrntNumbRslt: any = await new RelationApi().addprintedNumber(this.curGroup.Code, bankRslt.data.TokenWhiteListList[0].Code, payload)
      }
    } catch (error) {
      console.log(error)
    } */
  }

  deletePostToken (item: any, groupK: any, token: any) {
    this.isShowDelete = true
    this.overlay = true
    this.payloadDl = {
      item: item,
      groupK: groupK,
      token: token
    }
  }

  async deleteToken () {
    try {
      this.isDeleteToken = true
      const data = await new RelationApi().removeTokenWLGroup(this.curGroup.Code, this.payloadDl.item.Code, this.payloadDl.token.name) as any
      this.getWhiteList(this.curGroup.Description)
      this.isDeleteToken = false
    } catch (error) {
      console.log(error)
    }
    this.isShowDelete = false
    this.overlay = false
  }

  formatData () {
    const data: any = this._.cloneDeep(this.curGroup.TokenWhiteListList)
    /* let isFormat = false
    if (data.length > 0) {
      console.log(data[0])
      if (data[0].Tokens[0] !== undefined && data[0].Tokens[0].name !== undefined) {
        isFormat = true
      }
    }
    console.log('[DEBUG FORMAT IS : ] ' + isFormat) */
    // if (isFormat) {
    for (let index = 0; index < data.length; index++) {
      const tarriffs: any = data[index]
      const tokens: any = []
      for (let j = 0; j < tarriffs.Tokens.length; j++) {
        const token = tarriffs.Tokens[j]
        if (token.name === undefined) {
          const elm = {
            name: token,
            tariffCode: {
              Code: tarriffs.TariffCode
            },
            isReimbursement: (tarriffs.TariffCode !== 'COSTLESS')
          }
          tokens.push(elm)
        } else {
          tokens.push(token)
        }
      }
      tarriffs.Tokens = tokens
      data[index] = tarriffs
    }
    // }
    this.tokenList = data
  }

  async addChargePass (token: any) {
    const payload = {
      PrintedNumber: token
    }
    try {
      this.isAddCP = true
      // check als er al een costless whitelist bestaat ?
      const tokenWhiteListList: any = this.curGroup.TokenWhiteListList
      let isFound = false
      let pos = 0
      for (let index = 0; index < tokenWhiteListList.length && !isFound; index++) {
        isFound = tokenWhiteListList[index].TariffCode === 'COSTLESS'
        pos = index
      }
      if (isFound) {
        const wLCode = tokenWhiteListList[pos].Code
        const addPrntNumbRslt: any = await new RelationApi().addprintedNumber(this.curGroup.Code, wLCode, payload)
      } else {
        const payloadBody = {
          TariffCode: 'COSTLESS'
        }
        const bankRslt: any = await new RelationApi().createWhiteList(this.curGroup.Code, payloadBody)
        const addPrntNumbRslt: any = await new RelationApi().addprintedNumber(this.curGroup.Code, bankRslt.data.Code, payload)
      }
      // TODO REFRESH
      this.getWhiteList(this.curGroup.Description)
      this.whiteListGroup()
      this.isAddCP = false
    } catch (error) {
      console.log(error)
      this.isAddCP = false
    }
    /* if (this.tokenList.length > 0) {
      let isFound = false
      let pos = 0
      for (let index = 0; index < this.tokenList.length && !isFound; index++) {
        const element = this.tokenList[index]
        isFound = element.TariffCode === 'COSTLESS'
        pos = index
      }

      if (isFound && this.tokenList.length > 0) {
        const elmTK = {
          name: token,
          isReimbursement: false
        }
        this.tokenList[pos].Tokens.push(elmTK)
      } else {
        const elm: any = {
          Code: 'WL-TEST-TEST-TEST',
          Description: 'Test',
          WLType: 'WhiteList',
          TariffCode: 'COSTLESS',
          Tokens: []
        }
        const elmTK = {
          name: token,
          isReimbursement: false
        }
        elm.Tokens.push(elmTK)
        this.tokenList.push(elm)
      }
    } else {
      const elm: any = {
        Code: 'WL-TEST-TEST-TEST',
        Description: 'Test',
        WLType: 'WhiteList',
        TariffCode: 'COSTLESS',
        Tokens: []
      }
      const elmTK = {
        name: token,
        isReimbursement: false
      }
      elm.Tokens.push(elmTK)
      this.tokenList.push(elm)
    } */
  }

  async moveChargePass (token: any, tariff: any) {
    const payload = {
      PrintedNumber: token
    }
    try {
      // check als er al een costless whitelist bestaat ?
      const tokenWhiteListList: any = this.curGroup.TokenWhiteListList
      let isFound = false
      let pos = 0
      for (let index = 0; index < tokenWhiteListList.length && !isFound; index++) {
        isFound = tokenWhiteListList[index].TariffCode === 'COSTLESS'
        pos = index
      }
      if (isFound) {
        const wLCode = tokenWhiteListList[pos].Code
        const addPrntNumbRslt: any = await new RelationApi().addprintedNumber(this.curGroup.Code, wLCode, payload)
      } else {
        const payloadBody = {
          TariffCode: 'COSTLESS'
        }
        const bankRslt: any = await new RelationApi().createWhiteList(this.curGroup.Code, payloadBody)
        const addPrntNumbRslt: any = await new RelationApi().addprintedNumber(this.curGroup.Code, bankRslt.data.TokenWhiteListList[0].Code, payload)
      }
      // TODO REFRESH
      // this.whiteListGroup()
      this.getWhiteList(this.curGroup.Description)
      // console.log('REFRESH')
    } catch (error) {
      console.log(error)
    }
    /* if (this.tokenList.length > 0) {
      let isFound = false
      let pos = 0
      for (let index = 0; index < this.tokenList.length && !isFound; index++) {
        const element = this.tokenList[index]
        isFound = element.TariffCode === 'COSTLESS'
        pos = index
      }

      if (isFound && this.tokenList.length > 0) {
        const elmTK = {
          name: token,
          isReimbursement: false
        }
        this.tokenList[pos].Tokens.push(elmTK)
      } else {
        const elm: any = {
          Code: 'WL-TEST-TEST-TEST',
          Description: 'Test',
          WLType: 'WhiteList',
          TariffCode: 'COSTLESS',
          Tokens: []
        }
        const elmTK = {
          name: token,
          isReimbursement: false
        }
        elm.Tokens.push(elmTK)
        this.tokenList.push(elm)
      }
    } else {
      const elm: any = {
        Code: 'WL-TEST-TEST-TEST',
        Description: 'Test',
        WLType: 'WhiteList',
        TariffCode: 'COSTLESS',
        Tokens: []
      }
      const elmTK = {
        name: token,
        isReimbursement: false
      }
      elm.Tokens.push(elmTK)
      this.tokenList.push(elm)
    } */
  }

  startConfiguration () {
    this.dialog = true
    this.step = 0
  }

  cancelChargeCardAccess () {
    this.dialog = false
    this.step = 0
    // @ts-ignore
    this.isChargeCardAccess = this.contractDTA.isreimbtowhitelist
    // this.checkUserInfo()
    // this.getGroups()
    /* this.isChargeCardAccess = false
    this.isChargeCardReimbursement = false
    this.isReimbursement = false
    this.isCreateTariff = false */
  }

  editGroup (editGroup: any) {
    this.step = 2
    this.curGroup = editGroup
    this.formatData()
  }

  editGroupName (group: any) {
    this.step = 1
    this.payload.id = group.Id
    this.payload.groupName = group.Description
    this.payload.tokenWhiteListList = group.TokenWhiteListList
    this.payload.code = group.Code
    this.isEditNameGroup = true
  }

  duplicateGroup (group: any) {
    this.step = 1
    this.payload.id = group.Id
    this.payload.groupName = group.Description
    this.payload.code = group.Code
    this.isDuplicate = true
  }

  async whiteListGroup () {
    try {
      this.isLoadingWLG = true
      const data = await new RelationApi().getWhiteListGroup(this.relationId) as any
      this.tokensGroup = data.data.records
      this.isLoadingWLG = false
    } catch (error) {
      console.log(error)
    }
  }

  async getWhiteList (description: any) {
    try {
      const filter: any = { Description: description }
      const data = await new RelationApi().getWhiteListGroup(this.relationId, filter) as any
      console.log('[DEBUG] filter result')
      console.log(data)
      if (data.data.records.length > 0) {
        this.curGroup = data.data.records[0]
        this.formatData()
      }
      // this.tokensGroup = data.data.records
    } catch (error) {
      console.log(error)
    }
  }

  async removePostWLGroup (TGCode: any) {
    this.payloadDlGrp = {
      name: TGCode,
      code: TGCode.Code
    }
    this.isShowDeleteGroup = true
    this.overlayGroup = true
  }

  async removeWLGroup () {
    try {
      // remove from placement
      this.isDeleteGrp = true
      const data = await new RelationApi().removeWLGroup(this.relationId, this.payloadDlGrp.code) as any
      this.whiteListGroup()
    } catch (error) {
      console.log(error)
    }
    this.payloadDlGrp = {
      name: '',
      code: ''
    }
    this.isShowDeleteGroup = false
    this.overlayGroup = false
    this.isDeleteGrp = false
  }

  async getListTariff () {
    try {
      this.isLoadingTariff = true
      const data = await new RelationApi().getTariffs(this.relationId) as any
      this.tariffsTab = data.data.records
      this.isLoadingTariff = false
    } catch (error) {
      console.log(error)
    }
  }

  async checkUserInfo () {
    try {
      const result: any = await new UserApi().myInfo()
      this.relationId = this.usrDTA.data.Relation.Id
      this.getListTariff()
      this.whiteListGroup()
    } catch (error) {
      console.error(error)
    }
  }

  async newGroup () {
    this.isNewChargeCard = true
    if (this.isDuplicate) {
      this.newDuplicateGroup()
    } else {
      if (this.isEditNameGroup) {
        this.isEditNameGroup = false
        try {
          const payload = {
            Id: this.payload.id,
            Code: this.payload.code,
            Description: this.payload.groupName,
            WhiteListList: this.payload.tokenWhiteListList
          }
          const data = await new RelationApi().createGroup(this.relationId, payload) as any
          this.whiteListGroup()
          this.isDuplicate = false
          this.step = 0
        } catch (error) {
          console.log(error)
        }
      } else {
        try {
          const payload = {
            Code: this.payload.code,
            Description: this.payload.groupName,
            WhiteListList: []
          }
          console.log(payload)
          const data = await new RelationApi().createGroup(this.relationId, payload) as any
          this.whiteListGroup()
          this.isDuplicate = false
          this.step = 0
        } catch (error) {
          console.log(error)
        }
      }
    }
    this.isNewChargeCard = false
  }

  async newDuplicateGroup () {
    try {
      this.isNewChargeCard = true
      const data = await new RelationApi().duplicateGroup(this.payload.code, this.payload) as any
      this.whiteListGroup()
      this.isDuplicate = false
      this.step = 0
      this.isNewChargeCard = false
    } catch (error) {
      console.log(error)
    }
  }
}
