


























































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { mapGetters } from 'vuex'
import ChargepointCard from '../../components/chargepoint/ChargepointCard.vue'
import ChargeCardAccess from '../../components/chargepoint/ChargeCardAccess.vue'
import ChargeCardGuest from '../../components/chargepoint/ChargeCardGuest.vue'
import PlugCharge from '../../components/chargepoint/PlugCharge.vue'
import ChargepointContractCard from '../../components/chargepoint/ChargepointContractCard.vue'
import LastTenSessions from '../../components/chargepoint/LastTenSessions.vue'
import LastTenAuthorizes from '../../components/chargepoint/LastTenAuthorizes.vue'
import RemoteStart from '../../components/chargepoint/RemoteStart'
import Reset from '../../components/chargepoint/Reset'
import { Watch, Prop } from 'vue-property-decorator'
import ChargePointApi from '../../services/ChargePointApi'
import ChargepointEdit from './ChargepointEdit.vue'
import DefaultResponseInterface from '@/typings/DefaultResponseInterface'
import { ChargePoint, ChargePointUppercaseType, States } from '@/typings/ChargePoint'
import { BreadCrumbType } from '@/typings/ApiTypings/JiraSupportInterface'
import { Settings } from 'http2'

@Component({
  components: {
    ChargeCardAccess,
    ChargeCardGuest,
    PlugCharge,
    LastTenSessions,
    LastTenAuthorizes,
    ChargepointCard,
    RemoteStart,
    Reset,
    ChargepointEdit,
    ChargepointContractCard
  },
  filters: {
    capitalize: function (value: string) {
      if (!value) return ''
      value = value.toString()
      value = value.toLowerCase()
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  },
  computed: {
    ...mapGetters({ isLoading: 'chargepoints/detailIsloading', userDTA: 'user/userInfo' })
  }
})
export default class ChargepointDetail extends Vue {
  @Prop(String) readonly id!: string

  panel = [0, 1]
  settings:Settings|null = null
  contractState:Settings|null = null
  loader = false
  loadingPlugCharge = false
  isEditMap = false
  items: BreadCrumbType[] = []
  modeState = process.env.VUE_APP_MODE
  isLoadingSettings = false
  loading= false
  selection= 1

  chargePoint: ChargePointUppercaseType = {
    Id: '',
    State: '',
    Description: '',
    PublicIdentifier: '',
    Location: {
      Id: '',
      Street: '',
      HouseNumber: '',
      ZipCode: '',
      City: '',
      Country: '',
      Description: '',
      LandOwnerDescr: '',
      CpoDescr: '',
      Latitude: '',
      Longitude: ''
    },
    Connectors: []
  }

  fieldChargepoint = {
    label: '',
    description: '',
    street: '',
    housenumber: '',
    zipcode: '',
    country: '',
    city: ''
  }

  isSaving = false
  loadingChargepoint = true
  mounted () {
    this.fetchData()
  }

  async fetchData () {
    await this.getContracts()
    await this.getChargepointDetails()
    await this.getSettings()
  }

  async getSettings () {
    try {
      this.isLoadingSettings = true
      this.settings =  await new ChargePointApi().getSettings(this.id) as Settings
      this.isLoadingSettings = false
    } catch (error) {
      console.error(error)
    }
  }

  async getContracts () {
    try {
      this.contractState =  await new ChargePointApi().contracts(this.id) as Settings
    } catch (error) {
      console.error(error)
    }
  }

  async getChargepointDetails () {
    try {
      this.loadingChargepoint = true
      const rslt = await new ChargePointApi().details(this. id) as DefaultResponseInterface
      this.chargePoint.Id = rslt.data.Id
      this.chargePoint.Description = rslt.data.Description
      this.chargePoint.PublicIdentifier = rslt.data.PublicIdentifier
      this.chargePoint.State = rslt.data.State
      if (rslt.data.Location) {
        this.chargePoint.Location.Id = rslt.data.Location.Id
        this.chargePoint.Location.Street = rslt.data.Location.Street
        this.chargePoint.Location.HouseNumber = rslt.data.Location.HouseNumber
        this.chargePoint.Location.ZipCode = rslt.data.Location.ZipCode
        this.chargePoint.Location.City = rslt.data.Location.City
        this.chargePoint.Location.Country = rslt.data.Location.Country
        this.chargePoint.Location.Description = rslt.data.Location.Description
        this.chargePoint.Location.LandOwnerDescr = rslt.data.Location.LandOwnerDescr
        this.chargePoint.Location.CpoDescr = rslt.data.Location.CpoDescr
        this.chargePoint.Location.Latitude = rslt.data.Location.Latitude
        this.chargePoint.Location.Longitude = rslt.data.Location.Longitude
      }
      this.chargePoint.Connectors = rslt.data.Connectors
      this.initBreadCrumb()
      this.loadingChargepoint = false
    } catch (error) {
      console.error(error)
    }
  }

  initBreadCrumb () {
    this.items = [{
      text: 'My chargers',
      disabled: false,
      href: 'navchargepoints',
      to: { name: 'navchargepoints' },
      last: false
    },
    {
      text: this.chargePoint.PublicIdentifier,
      disabled: true,
      href: 'breadcrumbs_link_1',
      last: true
    }]
  }

  get usrDTA (): any {
    // @ts-ignore
    return this.userDTA
  }

  async getDetails (id: string) {
    await this.$store.dispatch('chargepoints/getDetails', id)
  }

  isCPOffline(state: string) {
    return !(state === States.available || state === States.AVAILABLE)
  }

  getColorByState(state: string) {
    if (state === States.available || state === States.AVAILABLE) {
      return States.AVAILABLE
    }
    return States.OFFLINE
  }

  getStateLabel(state: string) {
    return this.isCPOffline(state) ? 'Offline' :  'Online'
  }

  @Watch('id')
  idOnChanged () {
    this.fetchData()
  }

}
