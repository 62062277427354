





































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component
export default class LocationCard extends Vue {
  @Prop(String) readonly iconSetting: any
  @Prop(String) readonly color: any
  @Prop(String) readonly icon: any
  @Prop(String) readonly label: any
  @Prop({ type: Boolean, default: false }) readonly isIcon: any
  @Prop({ type: String, default: '32px' }) readonly size: any

}
