import BaseApi from './BaseApi'

export default class AuthorizeApi extends BaseApi {
  constructor (type = 0) {
    super('Authorize', type)
  }

  /* async TenLastAuthorizesFromPlacents (ids: Array<number|string>) {
    const options = { url: `/${process.env.VUE_APP_API_VERSION}/authorize/BCO/lasttenbycp?ids=${JSON.stringify(ids)}` }
    const dt = await this.getApi(options) as any
    return dt.data
  } */
  async TenLastAuthorizesFromPlacents (chargepointid: any) {
    const options = { url: `/${process.env.VUE_APP_API_VERSION}/authorize/BCO/lasttenbycp/${chargepointid}` }
    const dt = await this.getApi(options) as any
    return dt.data
  }
}
